import store from '@/store';
export default {
  data: function() {
    return {
      modal: false,
      item: {},
    };
  },
  methods: {
    async showModal(key, item, modulePath, subdomain) {
      this.$message.reset();
      if (item) this.item = item;
      if (typeof key === 'string') {
        this.$data[key] = true;
      } else {
        this.modal = true;
      }
      if (item?.id && modulePath) {
        await store.dispatch(modulePath, { id: item.id, subdomain: subdomain });
        this.modal = true;
      }
    },
    closeModal(key, callback = () => {}) {
      this.$store.dispatch('common/resetErrorFields');
      if (typeof key === 'string') {
        this.$data[key] = false;
      } else {
        this.modal = false;
      }
      callback();
    },
  },
};
