<template>
  <transition name="modal" appear>
    <div class="modal">
      <div class="modal-bg" @click.self="$emit('close')">
        <div class="modal-panel" :class="[className]">
          <div class="modal-header" v-if="this.$slots.headline">
            <h4 class="headline"><slot name="headline"></slot></h4>
          </div>
          <div class="modal-body" v-if="this.$slots.body">
            <slot name="body"></slot>
          </div>
          <div class="modal-footer" v-if="this.$slots.footer">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    className: {
      type: String,
      default: '',
    }
  },
};
</script>
