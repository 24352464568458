import { mapGetters } from 'vuex';

export default {
  data: function() {
    return {};
  },
  computed: {
    ...mapGetters({
      canUseCpmParentEvent: 'event/canUseCpmParentEvent',
      isUseCpmParentEvent: 'event/isUseCpmParentEvent',
      isClientChild: 'auth/isClientChild',
      isGmoOrOfficeLoginStore: 'common/isGmoOrOfficeLoginStore',
      parentEventDetail: 'event/parentEventDetail',
    }),
    navs() {
      return [
        {
          id: '01',
          name: 'アカウント設定',
          path: '/account/user',
          routeName: 'AccountUser',
          includes: false,
        },
        {
          id: '02',
          name: this.$permission.isOffice() && !this.isGmoOrOfficeLoginStore ? '事務局/企業設定' : '企業設定',
          path: '/account/company',
          routeName: 'AccountCompany',
          includes: false,
        },
        {
          id: '03',
          name: 'ユーザー設定',
          path: '/account/user/cpm',
          routeName: 'AccountUserCpm',
          includes: true,
        },
        {
          id: '04',
          name: 'ログインログ',
          path: '/account/log',
          routeName: 'AccountUserLog',
          includes: false,
        },
      ];
    },
    pageNavs() {
      if (this.canUseCpmParentEvent && this.isUseCpmParentEvent) {
        if ((this.isGmoOrOfficeLoginStore || this.$permission.isStore()) && !this.parentEventDetail?.hasJoinEventCpm) {
          return this.navs.slice(0, 2);
        } else if (this.isGmoOrOfficeLoginStore) {
          return this.navs.slice(2, 4);
        } else if (this.$permission.isStore()) {
          return this.navs;
        }
      }
      return this.navs.slice(0, 2);
    }
  },
};
