import { mapGetters } from 'vuex';
//mixins
import password from '@/mixins/plugin/password';
import nav from '@/mixins/nav/account';
import error from '@/mixins/plugin/error';
//component
import FormRow from '@/components/FormRow.vue';

export default {
  data: function() {
    return {};
  },
  computed: {
    ...mapGetters({
      infor: 'auth/infor',
      subdomain: 'auth/commonSubdomain',
      shopId: 'auth/commonShopId',
    }),
    id: function() {
      return this.$route.params.id;
    },
  },
  mixins: [nav, error, password],
  components: {
    FormRow,
  },
  methods: {
    dispatchAction(actionPath, payload) {
      return this.$store.dispatch(actionPath, {
        subdomain: this.subdomain,
        shopId: this.shopId,
        ...payload,
      })
    },
    getCpmUsers() {
      return this.dispatchAction('settingUserCpm/getCpmUsers');
    },
    getCpmUserDetail() {
      return this.dispatchAction('settingUserCpm/getCpmUserDetail');
    }
  },
  async mounted() {
    const loading = this.$loading.show();
    Promise.all([
      await this.$store.dispatch('shop/getShopDetail', {
        subdomain: this.subdomain,
        id: this.shopId,
      }),
    ]).then(() => {
      if (!this.parentEventDetail.hasJoinEventCpm) {
        this.$router.push({ name: '404' });
      }
    }).finally(() => {
      this.$loading.clear(loading);
    });
  }
}
