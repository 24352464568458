<template>
  <div class="form-row" :class="[classNames]">
    <p class="my-2" v-if="this.$slots.description"><slot name="description"></slot></p>
    <p class="form-label" v-if="this.$slots.label">
      <span class="form-required" v-if="required">必須</span>
      <span class="form-need" v-if="need">公開に必要</span>
      <slot name="label"></slot>
      <span class="form-label-note" v-if="this.$slots.labelNote"><slot name="labelNote"></slot></span>
      <span class="form-label-toggle" v-if="this.$slots.toggle">
        <slot name="toggle"></slot>
      </span>
      <span class="pos-end" v-if="this.$slots.endLabel">
        <slot name="endLabel"></slot>
      </span>
    </p>
    <p class="my-2" v-if="this.$slots.subLabel"><slot name="subLabel"></slot></p>
    <div class="form-content" v-if="this.$slots.content">
      <slot name="content"></slot>
    </div>
    <p class="my-2" v-if="this.$slots.subLabelNote"><slot name="subLabelNote"></slot></p>
  </div>
</template>

<script>
export default {
  name: 'FormRow',
  props: {
    required: {
      default: false,
      type: Boolean,
    },
    need: {
      default: false,
      type: Boolean,
    },
    isCustomRow: {
      default: false,
      type: Boolean,
    },
    isHeaderRow: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    classNames: function() {
      return {
        'custom-form-row': this.isCustomRow,
        'form-row-header': this.isHeaderRow
      };
    },
  }
};
</script>

<style scoped>
.form-row-header {
  margin: 0;
  padding: 16px 20px 16px 32px !important;
}

.form-row-header:not(:last-child) {
  border-bottom: 1px solid #e1e2eb;
}

.form-row-header > .form-label > .form-label-toggle {
  margin-left: auto;
}

.form-row-header > .form-label {
  font-weight: normal;
}
</style>
